import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
  withUser,
  withUserAgent,
  withGameActions,
  withCoreComponent,
} from 'core/hocs';
import {
  RouteLink,
  FormattedTag,
  MostPopularGames as MostPopularGamesCore,
} from 'core/components';
import { GAMES_CATEGORY_PAGE } from 'core/constants';

import { GamesCarousel } from 'components/games-carousel/games-carousel';

import './most-popular-games.scss';

export const MostPopularGamesUI = ({
  games,
  className,
  isGamePage,
  isUserLoggedIn,
}) => (
  <div className={classNames('most-popular-container', className, {
    loggedIn: isUserLoggedIn,
    notLoggedIn: !isUserLoggedIn,
  })}
  >
    <div className="most-popular-header">
      {isUserLoggedIn ? (
        <FormattedTag
          tag="div"
          id="games.most-popular.title"
          className="font-weight-bold letter-spacing-default text-uppercase most-popular-title games-title"
        />
      ) : (
        <>
          <FormattedTag
            tag="div"
            id="games.title.play"
            className="font-weight-bold letter-spacing-default text-uppercase most-popular-title games-title"
          />&nbsp;&nbsp;
          <FormattedTag
            tag="div"
            id="games.title.top"
            className="font-weight-bold letter-spacing-default text-uppercase most-popular-title games-title text-primary"
          />&nbsp;&nbsp;
          <FormattedTag
            tag="div"
            id="games.title.games"
            className="font-weight-bold letter-spacing-default text-uppercase most-popular-title games-title"
          />
        </>
      )}

      {isUserLoggedIn && (
        <FormattedTag
          id="see-all"
          tag={RouteLink}
          className="text-decoration-none font-weight-strong show-more"
          to={`/category/${GAMES_CATEGORY_PAGE.TOP_GAMES}`}
        />
      )}
    </div>

    <GamesCarousel
      isUserLoggedIn={isUserLoggedIn}
      isGamePage={isGamePage}
      withStubs={isUserLoggedIn}
      isArrowsToShow={false}
      games={games}
      rows={isUserLoggedIn ? 2 : 3}
    />

    {!isUserLoggedIn && (
      <div className="w-100 d-flex justify-content-center mt-4 show-more">
        <FormattedTag
          id="games.show-more-games"
          tag={RouteLink}
          className="btn btn-primary btn-lg"
          to={`/category/${GAMES_CATEGORY_PAGE.TOP_GAMES}`}
        />
      </div>
    )}
  </div>
);
MostPopularGamesUI.propTypes = {
  isGamePage: PropTypes.bool,
  isUserLoggedIn: PropTypes.bool.isRequired,
  className: PropTypes.string,
  games: PropTypes.arrayOf(PropTypes.shape({})),
};

MostPopularGamesUI.defaultProps = {
  games: [],
  className: null,
  isGamePage: false,
};

export const MostPopularGames = withGameActions(
  withUserAgent(
    withUser(
      withCoreComponent(MostPopularGamesCore, MostPopularGamesUI),
    ),
  ),
);
