import React from 'react';
import PropTypes from 'prop-types';

import {
  RouteLink,
  FormattedTag,
  RecommendedGames as RecommendedGamesCore
} from 'core/components';
import { withCoreComponent } from 'core/hocs';
import { GAMES_CATEGORY_PAGE } from 'core/constants';

import { GamesCarousel } from 'components/games-carousel/games-carousel';

import './recommended-games.scss';

export const RecommendedGamesUI = ({
  games,
  isUserLoggedIn,
  className,
  isGamePage,
}) => (
  <div className={className}>
    <div className="d-flex justify-content-between align-items-center mb-1_75 mb-md-2_5">
      <div className="d-flex align-items-center">
        <FormattedTag
          id="games.recommended"
          className="font-weight-bold letter-spacing-default text-uppercase games-title"
        />
      </div>
      <FormattedTag
        id="see-all"
        tag={RouteLink}
        to={`/category/${GAMES_CATEGORY_PAGE.RECOMMENDED}`}
        className="text-decoration-none font-weight-strong show-more"
      />
    </div>

    {/* MOBILE */}
    <GamesCarousel
      isUserLoggedIn={isUserLoggedIn}
      isGamePage={isGamePage}
      games={games}
      isArrowsToShow={false}
    />
  </div>
);

RecommendedGamesUI.propTypes = {
  isUserLoggedIn: PropTypes.bool.isRequired,
  games: PropTypes.arrayOf(PropTypes.shape({})),
  className: PropTypes.string,
  isGamePage: PropTypes.bool
};

RecommendedGamesUI.defaultProps = {
  games: [],
  className: null,
  isGamePage: false
};

export const RecommendedGames = withCoreComponent(
  RecommendedGamesCore,
  RecommendedGamesUI
);
