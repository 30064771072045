import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import {
  RouteLink,
  FormattedTag,
  NewestGames as NewestGamesCore
} from 'core/components';
import { withCoreComponent } from 'core/hocs';
import { GAMES_CATEGORY_PAGE } from 'core/constants';

import { GamesCarousel } from 'components/games-carousel/games-carousel';

import './newest-games.scss';

export const NewestGamesUI = ({
  games,
  isUserLoggedIn,
  className,
  isInProgress,
}) => (
  <div className={classNames('newest-games', className)}>
    <div className="d-flex justify-content-between align-items-center mb-1_75">
      <div className="d-flex align-items-center">
        <FormattedTag
          id="games.newest"
          className="font-weight-bold letter-spacing-default text-uppercase games-title"
        />
      </div>
      <FormattedTag
        id="see-all"
        tag={RouteLink}
        to={`/category/${GAMES_CATEGORY_PAGE.NEWEST}`}
        className="text-decoration-none font-weight-strong show-more"
      />
    </div>

    <GamesCarousel
      isInProgress={isInProgress}
      isUserLoggedIn={isUserLoggedIn}
      withStubs={isUserLoggedIn}
      isArrowsToShow={false}
      games={games}
    />
  </div>
);

NewestGamesUI.propTypes = {
  isUserLoggedIn: PropTypes.bool.isRequired,
  isInProgress: PropTypes.bool.isRequired,
  games: PropTypes.arrayOf(PropTypes.shape({})),
  className: PropTypes.string,
};

NewestGamesUI.defaultProps = {
  games: [],
  className: null,
};

export const NewestGames = withCoreComponent(NewestGamesCore, NewestGamesUI);
