import React from 'react';
import PropTypes from 'prop-types';

import { FormattedTag, RouteLink } from 'core/components';

import { GamesCarousel } from 'components/games-carousel/games-carousel';

import '../all-games.scss';

export const LoggedIn = ({ games }) => (
  <div>
    <div className="mb-1_75 mb-md-2_5 d-flex justify-content-between align-items-center">
      <FormattedTag
        id="all-games"
        className="font-weight-bold letter-spacing-default text-uppercase games-title"
        isHtml
      />

      <FormattedTag
        id="see-all"
        tag={RouteLink}
        to="/games"
        className="text-decoration-none font-weight-strong show-more"
      />
    </div>

    <GamesCarousel
      isArrowsToShow={false}
      games={games}
      rows={2}
      isUserLoggedIn
      category="all"
    />
  </div>
);

LoggedIn.propTypes = {
  games: PropTypes.arrayOf(PropTypes.shape),
};

LoggedIn.defaultProps = {
  games: [],
};

